import { Image } from '@chakra-ui/react';
import logo from '../assets/Kalmar_logo.png'; // Make sure to add your logo to assets folder


export const Logo = () => (
  <Image
    src={logo}
    alt="Kalmar"
    height="12"
    width="auto"
  />
);