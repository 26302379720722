import {
  Box,
  Button,
  Card,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';
import { useState, useEffect } from 'react';
import { Logo } from './Logo';

export function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function checkAuthState() {
      try {
        await getCurrentUser();
        navigate('/');
      } catch {
        // User is not authenticated
      }
    }
    checkAuthState();
  }, [navigate]);

  const signIn = async () => {
    try {
      setIsLoading(true);
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error signing in:', error);
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="md" py={{ base: '12', md: '24' }}>
      <Card.Root w="full" bg="bg.surface">
        <Card.Body>
          <Stack gap="8" align="center">
            {/* Header */}
            <Stack gap="6" align="center">
              <Box bg="white" p="2" borderRadius="md">
                <Logo />
              </Box>
              <Stack gap="3" align="center">
                <Heading size="lg">Sign in</Heading>
                <Text color="fg.muted">
                  Gateway Debugging application
                </Text>
              </Stack>
            </Stack>

            {/* Sign in Button */}
            <Stack gap="6" w="full">
              <Button
                onClick={signIn}
                variant="outline"
                w="full"
              >
                {isLoading ? "Signing in" : (
                  <>
                    <FcGoogle /> Continue with Google
                  </>
                )}
              </Button>
            </Stack>

            {/* Footer */}
            <Box as="footer">
              <Stack gap="4" align="center">
                <Text fontSize="sm" color="fg.muted">
                  © {new Date().getFullYear()} Kalmar. All rights reserved.
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Card.Body>
      </Card.Root>
    </Container>
  );
}