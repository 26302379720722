/// <reference types="web-bluetooth" />

import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  VStack,
  Text,
  Spinner,
  Card,
  List,
  ListItem,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { LuCheckCircle, LuInfo} from "react-icons/lu"  
import { Toaster } from "./ui/toaster"
import { toaster } from "./ui/toast-config"
import { Navigation } from './Navigation';
import { post } from "aws-amplify/api"
import { fetchAuthSession } from 'aws-amplify/auth';

interface BluetoothDevice {
  name: string;
  id: string;
}

interface DebugData {
  gatewayId: string;
  timestamp: string;
  status: {
    connection: string;
    lastSeen: string;
    networkInterfaces: {
      [key: string]: NetworkInterface;
    };
    gatewaySN?: string;
    machineSN?: string;
    memoryFree?: number;
    kdcVersion?: string;
    configVersion?: string;
    imageVersion?: string;
    mcuFirmwareVersion?: string;
    errors?: {
      timestamp: string;
      source: string;
      errorId: string;
      state: string;
    }[];
  };
  rawData?: {
    report?: string;
    info?: string;
    log?: string;
  };
}

interface NetworkInterface {
  state: string;
  stateReason?: string;
  apn?: string;
  details?: string;
}

const CONN_SERVICE_UUID = '68161c07-bde3-4769-9202-7f15f3685df9';
const CONN_REPORT_CHARACTERISTIC_UUID = '68161c08-bde3-4769-9202-7f15f3685df9';
const CONN_INFO_CHARACTERISTIC_UUID = '68161c09-bde3-4769-9202-7f15f3685df9';
const CONN_LOG_CHARACTERISTIC_UUID = '68161c0a-bde3-4769-9202-7f15f3685df9';

const COMMAND_SERVICE_UUID = '68161c17-bde3-4769-9202-7f15f3685df9';
const decoder = new TextDecoder('utf-8');


export const GatewayDebugger = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /Android/.test(navigator.userAgent);

  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [debugData, setDebugData] = useState<DebugData | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [server, setServer] = useState<BluetoothRemoteGATTServer | null>(null);
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const [progress, setProgress] = useState<string>('');
  const [dataPointsCollected, setDataPointsCollected] = useState<number>(0);
  const [currentDataPoint, setCurrentDataPoint] = useState<number>(0);
  const [allDebugData, setAllDebugData] = useState<DebugData[]>([]);

  useEffect(() => {
    return () => {
      if (server?.connected) {
        server.disconnect();
      }
    };
  }, [server]);

  const readReport = async (server: BluetoothRemoteGATTServer) => {
    if (isOperationInProgress) {
      console.log('GATT operation in progress, skipping report read...');
      return null;
    }
    
    try {
      setIsOperationInProgress(true);
      const service = await server.getPrimaryService(CONN_SERVICE_UUID);
      const characteristic = await service.getCharacteristic(CONN_REPORT_CHARACTERISTIC_UUID);
      const value = await characteristic.readValue();
      const report = decoder.decode(value);
      
      const networkInterfaces: { [key: string]: NetworkInterface } = {};
      
      const uniqueInterfaces = [...new Set(report.split('|'))]
        .filter(entry => entry.trim())
        .map(entry => entry.trim());

      uniqueInterfaces.forEach((interface_str) => {
        if (interface_str.startsWith('wwan0 connected:')) {
          const matches = interface_str.match(/wwan0 connected:\s*(yes|no)(?:\s+apn:\s*([^\s|]+))?/i);
          if (matches) {
            networkInterfaces['wwan0'] = {
              state: matches[1].toLowerCase() === 'yes' ? 'connected' : 'disconnected',
              apn: matches[2] || 'Not Available',
              details: interface_str
            };
          }
          return;
        }

        const [name, ...statusParts] = interface_str.split(':');
        const trimmedName = name?.trim();
        
        if (!trimmedName || statusParts.length === 0) return;

        const status = statusParts.join(':').trim();

        if (trimmedName === 'Registration state' || trimmedName === 'Signal quality') {
          networkInterfaces[trimmedName] = {
            state: status.trim(),
            details: status
          };
        } else {
          const [state, stateReason] = status.split(':').map(s => s.trim());
          networkInterfaces[trimmedName] = {
            state: state || 'Unknown',
            stateReason: stateReason || 'No reason provided',
            details: status
          };
        }
      });

      const deviceName = server.device.name || 'Unknown';
      
      const cleanReport = [...new Set(report.split('|'))]
        .filter(entry => entry.trim())
        .map(entry => {
          return entry
            .replace(/:\s*(\d+)([a-zA-Z])/g, ': $1|$2') 
            .replace(/(\d)can0/g, '$1|can0');           
        })
        .join('|');

      const reportData = {
        gatewayId: deviceName,
        timestamp: new Date().toISOString(),
        status: {
          connection: 'Connected',
          lastSeen: new Date().toISOString(),
          networkInterfaces
        },
        rawData: {
          report: cleanReport
        }
      };

      setDebugData(reportData);
      return reportData;
    } catch (error) {
      console.error('Error reading report:', error);
      throw error;
    } finally {
      setIsOperationInProgress(false);
    }
  };

  const readAdditionalData = async (server: BluetoothRemoteGATTServer, baseData: DebugData) => {
    if (isOperationInProgress) {
      console.log('GATT operation in progress, skipping additional data read...');
      return baseData;
    }
    
    try {
      setIsOperationInProgress(true);
      const service = await server.getPrimaryService(CONN_SERVICE_UUID);

      const infoCharacteristic = await service.getCharacteristic(CONN_INFO_CHARACTERISTIC_UUID);
      const infoValue = await infoCharacteristic.readValue();
      const infoText = decoder.decode(infoValue);

      const infoMap: { [key: string]: string } = {};
      infoText.split('|')
        .filter(item => item.trim())
        .forEach(item => {
          const [key, ...valueParts] = item.split(':');
          if (key && valueParts.length > 0) {
            const value = valueParts.join(':').trim(); // Rejoin in case value contains colons
            infoMap[key.trim()] = value;
          }
        });

      // Read log characteristic
      const logCharacteristic = await service.getCharacteristic(CONN_LOG_CHARACTERISTIC_UUID);
      const logValue = await logCharacteristic.readValue();
      const logText = decoder.decode(logValue);

      const uniqueLogLines = [...new Set(logText.split('\n'))]
        .filter(line => {
          const trimmedLine = line.trim();
          return trimmedLine && 
                 /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}\.\d{6}\ssource:.*(?:activated|deactivated)$/.test(trimmedLine);
        });

      const cleanLog = uniqueLogLines.join('\n');

      const errors = uniqueLogLines
        .map(line => {
          try {
            const [timestampPart, ...rest] = line.split('source:');
            const timestamp = timestampPart.trim();
            
            if (!rest.length) return null;

            const restPart = rest.join('source:').trim();
            const [source, errorPart] = restPart.split(',errorId:').map(s => s.trim());
            
            if (!errorPart) return null;

            const [errorId, state] = errorPart.split(/\s+(activated|deactivated)$/);

            return {
              timestamp,
              source: source || 'Unknown',
              errorId: errorId?.trim() || 'Unknown',
              state: state?.trim() || 'Unknown'
            };
          } catch (e) {
            console.warn('Failed to parse error log line:', line, e);
            return null;
          }
        })
        .filter((error): error is NonNullable<typeof error> => error !== null);

      const fullData = {
        ...baseData,
        status: {
          ...baseData.status,
          gatewaySN: infoMap['GW SN'] || 'N/A',
          machineSN: infoMap['Machine SN'] || 'N/A',
          memoryFree: parseInt(infoMap['memory free']?.replace(/[^\d]/g, '') || '0'),
          kdcVersion: infoMap['KDC ver'] || 'N/A',
          configVersion: infoMap['config ver'] || 'N/A',
          imageVersion: infoMap['image'] || 'N/A',
          mcuFirmwareVersion: infoMap['MCU FW ver'] || 'N/A',
          errors
        },
        rawData: {
          ...baseData.rawData,
          info: infoText,
          log: cleanLog
        }
      };

      setDebugData(fullData);
      return fullData;
    } catch (error) {
      console.error('Error reading additional data:', error);
      throw error;
    } finally {
      setIsOperationInProgress(false);
    }
  };

  const fetchDebugData = async (server: BluetoothRemoteGATTServer) => {
    try {
      const reportData = await readReport(server);
      if (!reportData) return null;
      
      await new Promise(resolve => setTimeout(resolve, 500));
      const fullData = await readAdditionalData(server, reportData);
      return fullData;
    } catch (error) {
      console.error('Error fetching debug data:', error);
      return null;
    }
  };

  const collectAndSendData = async (server: BluetoothRemoteGATTServer) => {
    try {
      setProgress('Starting data collection process...');
      const collectedData: DebugData[] = [];
      
      setProgress('Collecting data point 1 of 3...');
      const firstData = await fetchDebugData(server);
      if (firstData) {
        collectedData.push({ ...firstData });
        setAllDebugData([...collectedData]);
        setDebugData(firstData);
        setDataPointsCollected(1);
        setCurrentDataPoint(0);
      }

      for (let i = 1; i < 3; i++) {
        setProgress(`Waiting 60 seconds before next collection...`);
        await new Promise(resolve => setTimeout(resolve, 600));
        
        setProgress(`Collecting data point ${i + 1} of 3...`);
        const currentData = await fetchDebugData(server);
        if (currentData) {
          collectedData.push({ ...currentData });
          setAllDebugData([...collectedData]);
          setDebugData(currentData);
          setDataPointsCollected(i + 1);
          setCurrentDataPoint(i);
        }
      }

      if (collectedData.length > 0) {
        setProgress('Sending collected data...');
        try {
          const { tokens } = await fetchAuthSession();
          const idToken = tokens?.idToken;

          const restOperation = await post({
            apiName: 'debuggerApi',
            path: '/',
            options: {
              headers: {
                Authorization: `Bearer ${idToken}`
              },
              body: {
                debugData: collectedData,
                gatewayId: collectedData[0]?.gatewayId
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as { [key: string]: any }
            }
          });

          const { statusCode, body } = await restOperation.response;
          const response = await body.json();

          if (response && statusCode === 200) {
            if (collectedData.length === 3) {
              setProgress('Data sent successfully.');
              toaster.success({
                title: 'Success',
                description: `Successfully collected and sent ${collectedData.length} data points`,
                duration: 6000,
              });
            } else {
              setProgress('Partial data sent.');
              toaster.create({
                title: 'Partial Success',
                description: `Failed to collect all 3 data points, but sent ${collectedData.length} data point(s)`,
                duration: 6000,
                type: "warning"
              });
            }
          } else {
            throw new Error('Failed to send data to server');
          }
        } catch (error) {
          console.error('Failed to send data:', error);
          toaster.error({
            title: 'Error',
            description: 'Failed to send data to server. Please take a screenshot of the debug data.',
            duration: 6000,
          });
          return;
        }
      }
    } catch (error) {
      console.error('Error in data collection process:', error);
      setProgress('Error occurred during data collection');
      toaster.error({
        title: 'Error',
        description: 'Error during data collection. Please take a screenshot if any data is visible.',
        duration: 6000,
      });
    }
  };

  const clearDebugData = () => {
    setDebugData(null);
    setAllDebugData([]);
    setDevice(null);
    setServer(null);
    setDataPointsCollected(0);
    setProgress('');
    setCurrentDataPoint(0);
  };

  const connectToDevice = async () => {
    try {
      clearDebugData();

      if (isIOS) {
        toaster.error({
          title: 'iOS Not Supported',
          description: 'Please use Chrome on Android or Desktop Chrome/Edge.',
          duration: 6000,
        });
        return;
      }

      if (!navigator.bluetooth) {
        toaster.error({
          title: 'Browser Not Supported',
          description: 'Please use Chrome browser for Bluetooth support.',
          duration: 6000,
        });
        return;
      }

      setIsConnecting(true);
      setProgress('Searching for gateway...');

      const device = await navigator.bluetooth.requestDevice({
        filters: [
          { services: [CONN_SERVICE_UUID] },
          { services: [COMMAND_SERVICE_UUID] },
          { namePrefix: 'GW-' },
          { namePrefix: 'cargotec-gw-' },
          { namePrefix: 'gw-' }
        ],
        optionalServices: [CONN_SERVICE_UUID, COMMAND_SERVICE_UUID]
      });

      setProgress('Gateway found. Establishing connection...');
      const newServer = await device.gatt?.connect();
      if (!newServer) {
        throw new Error('Failed to establish GATT connection');
      }

      setServer(newServer);
      setDevice({ name: device.name || 'Unknown Device', id: device.id });
      
      setProgress('Connection established. Starting data collection...');
      await collectAndSendData(newServer);

    } catch (error) {
      console.error('Connection error:', error);
      toaster.error({
        title: 'Error',
        description: `Failed to connect: ${error}`,
        duration: 6000,
      });
      clearDebugData();
    } finally {
      setIsConnecting(false);
    }
  };

  const showNextDataPoint = () => {
    if (currentDataPoint < allDebugData.length - 1) {
      setCurrentDataPoint(prev => prev + 1);
      setDebugData(allDebugData[currentDataPoint + 1]);
    }
  };

  const showPreviousDataPoint = () => {
    if (currentDataPoint > 0) {
      setCurrentDataPoint(prev => prev - 1);
      setDebugData(allDebugData[currentDataPoint - 1]);
    }
  };

  return (
    <>
      <Navigation />
      <Toaster />
      <Container maxW="container.md" py={8}>
        <VStack gap={6}>
          <Heading>Gateway Debugger</Heading>
          {isIOS ? (
            <Card.Root variant="outline" p={6}>
              <VStack gap={4}>
                <Text color="red.500" fontWeight="bold">
                  iOS Devices Not Supported
                </Text>
                <Text textAlign="center">
                  Due to iOS restrictions, please use one of these alternatives:
                </Text>
                <List.Root gap={2}>
                  <ListItem display="flex" alignItems="center">
                    <Box as={List.Indicator} color="green.500" mr={2} />
                    <Box as={LuCheckCircle} mr={2} />
                    Use Chrome on a desktop computer
                  </ListItem>
                  <ListItem display="flex" alignItems="center">
                    <Box as={List.Indicator} color="green.500" mr={2} />
                    <Box as={LuCheckCircle} mr={2} />
                    Use Chrome on an Android device
                  </ListItem>
                  <ListItem display="flex" alignItems="center">
                    <Box as={List.Indicator} color="blue.500" mr={2} />
                    <Box as={LuInfo} mr={2} />
                    Contact support for manual debugging
                  </ListItem>
                </List.Root>
              </VStack>
            </Card.Root>
          ) : (
            <>
              <VStack gap={4} align="stretch">
                <Text textAlign="center" fontWeight="medium">
                  This application requires a Bluetooth connection to communicate with your gateway. 
                  Please ensure your gateway is powered on and in discoverable mode, which typically 
                  occurs during the first 5 minutes after the gateway starts up.
                </Text>
                
                <Text textAlign="center" fontWeight="medium">
                  Once your gateway is ready, just click once to start, and we'll automatically collect diagnostic data 
                  from your gateway (takes about 3 minutes).
                </Text>
                
                {isAndroid && !navigator.bluetooth && (
                  <Text textAlign="center" color="red.500">
                    Please use Chrome browser for Bluetooth support.
                  </Text>
                )}
              </VStack>

              <Button
                colorScheme="blue"
                onClick={connectToDevice}
                size="lg"
                width="full"
                maxW="300px"
              >
                {isConnecting ? (
                  <>
                    <Spinner size="sm" mr={2} />
                    {device ? 'Gathering Data...' : 'Connecting...'}
                  </>
                ) : (
                  'Start Debug Process'
                )}
              </Button>

              {device && (
                <Box 
                  display="flex" 
                  alignItems="center" 
                  gap={2} 
                  p={2} 
                  bg="gray.50" 
                  borderRadius="md"
                >
                  <Box 
                    w={2} 
                    h={2} 
                    borderRadius="full" 
                    bg="green.400" 
                  />
                  <Text>Connected to: {device.name}</Text>
                </Box>
              )}

              {progress && (
                <Box w="100%" p={4} borderRadius="md" borderWidth={1} bg="gray.50">
                  <Text fontWeight="bold">Status:</Text>
                  <Text>{progress}</Text>
                  {dataPointsCollected > 0 && (
                    <Text mt={2}>
                      Data points collected: {dataPointsCollected}/3
                    </Text>
                  )}
                </Box>
              )}

              {debugData && (
                <Card.Root p={4} w="100%">
                  <Flex justify="space-between" align="center" mb={4}>
                    <Text fontWeight="bold">Debug Data Point {currentDataPoint + 1} of {allDebugData.length}:</Text>
                    <HStack>
                      <Button
                        size="sm"
                        onClick={showPreviousDataPoint}
                        disabled={currentDataPoint === 0}
                      >
                        Previous
                      </Button>
                      <Button
                        size="sm"
                        onClick={showNextDataPoint}
                        disabled={currentDataPoint === allDebugData.length - 1}
                      >
                        Next
                      </Button>
                    </HStack>
                  </Flex>

                  <VStack align="stretch" gap={2}>
                    <Text>Gateway ID: {debugData.gatewayId}</Text>
                    <Text>Timestamp: {debugData.timestamp}</Text>
                    <Text>Last Seen: {debugData.status.lastSeen}</Text>
                    
                    {debugData.status.networkInterfaces && (
                      <Box>
                        <Text fontWeight="bold" mb={1}>Network Interfaces:</Text>
                        {Object.entries(debugData.status.networkInterfaces).map(([name, info]) => (
                          <Box key={name} pl={4}>
                            <Text>
                              {name}: {info.state}
                              {info.apn ? ` (APN: ${info.apn})` : ''}
                            </Text>
                            {info.stateReason && (
                              <Text fontSize="sm" color="gray.600" pl={2}>
                                State Reason: {info.stateReason}
                              </Text>
                            )}
                          </Box>
                        ))}
                      </Box>
                    )}

                    {debugData.status.gatewaySN && (
                      <Box>
                        <Text fontWeight="bold" mb={1}>Gateway Information:</Text>
                        <VStack align="stretch" pl={4} gap={1}>
                          <Text>Gateway SN: {debugData.status.gatewaySN}</Text>
                          <Text>Machine SN: {debugData.status.machineSN}</Text>
                          <Text>Memory Free: {debugData.status.memoryFree} bytes</Text>
                          <Text>KDC Version: {debugData.status.kdcVersion}</Text>
                          <Text>Config Version: {debugData.status.configVersion}</Text>
                          <Text>Image Version: {debugData.status.imageVersion}</Text>
                          <Text>MCU Firmware Version: {debugData.status.mcuFirmwareVersion}</Text>
                        </VStack>
                      </Box>
                    )}

                    {debugData.status.errors && debugData.status.errors.length > 0 && (
                      <Box>
                        <Text fontWeight="bold" mb={1}>Recent Errors:</Text>
                        <VStack align="stretch" pl={4} gap={1}>
                          {debugData.status.errors.map((error, index) => (
                            <Text key={index} color="red.500">
                              {error.timestamp}: {error.source} - {error.errorId} ({error.state})
                            </Text>
                          ))}
                        </VStack>
                      </Box>
                    )}
                  </VStack>

                  <Button
                    mt={6}
                    colorScheme="red"
                    onClick={clearDebugData}
                    width="full"
                    disabled={isConnecting || isOperationInProgress || progress.includes('Collecting')}
                  >
                    Clear All Data
                  </Button>
                </Card.Root>
              )}
            </>
          )}
        </VStack>
      </Container>
    </>
  );
};