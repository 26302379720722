/* eslint-disable react-refresh/only-export-components */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, defaultSystem } from "@chakra-ui/react"
import { ThemeProvider } from "next-themes"


import { Amplify } from 'aws-amplify';
import App from './App';



function Main() {
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await fetch('/config.json');  
        const configData = await response.json();
  

        Amplify.configure({
          Auth: {
            Cognito: {
              userPoolClientId: configData.VITE_USER_POOL_CLIENT_ID,
              userPoolId: configData.VITE_USER_POOL_ID,
              loginWith: {
                oauth: {
                  domain: configData.VITE_COGNITO_DOMAIN,
                  scopes: ['openid', 'email', 'profile'],
                  redirectSignIn: [configData.VITE_REDIRECT_SIGN_IN],
                  redirectSignOut: [configData.VITE_REDIRECT_SIGN_OUT],
                  responseType: 'code'
                }
              }
            }
          },
          API: {
            REST: {
              debuggerApi: {
                endpoint: configData.VITE_API_URL
              }
            }
          }
        });

        setConfigLoaded(true);
      } catch (error) {
        console.error('Failed to load config:', error);
      }
    }

    fetchConfig();
  }, []);

  if (!configLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <React.StrictMode>
      <ChakraProvider value={defaultSystem}>
        <ThemeProvider attribute="class" disableTransitionOnChange>
          <App />
        </ThemeProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);